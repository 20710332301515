import React, { useState } from "react";
import Tooltip from "components/tooltip/tooltip";
import { minusIcon, plusIcon } from "base/SVG";
import styles from "./detail.module.scss";
import { AnimatePresence, motion } from "framer-motion";

export default function AddInfo({ itemData, id }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={styles["block__inner"]}>
      <div className={styles["blockItem"]}>
        <div className={styles["blockItem__title"]}>
          <Tooltip type={"right"} text="Program logs" />
          <p className="sm">Program Logs</p>
        </div>
        <div
          className={styles["blockItem__collapse"]}
          onClick={() => setIsOpen(!isOpen)}
        >
          <button type="button">{isOpen ? minusIcon : plusIcon}</button>
          <p className="sm"> Click to collaps program log</p>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles["blockItem__items"]}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className={styles["blockItem"]}>
              <div className={styles["blockItem__title"]}>
                <Tooltip
                  type={"right"}
                  text="Instruction ID of the program"
                />
                <p className="sm">Instruction ID</p>
              </div>
              <div className={styles["blockItem__text"]}>
                <div className={styles["blockItem__text-log"]}>
                  <p className="sm">
                    {id}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles["blockItem"]}>
              <div className={styles["blockItem__title"]}>
                <Tooltip
                  type={"right"}
                  text="Log details of the program"
                />
                <p className="sm">Log Details</p>
              </div>
              <div className={styles["blockItem__text"]}>
                <div className={styles["blockItem__text-log"]}>
                  {
                    itemData?.logs?.map((log, index) => {
                      return (
                        <p key={index} className="sm">
                          - {log}
                        </p>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div className={styles["blockItem"]}>
              <div className={styles["blockItem__title"]}>
                <Tooltip
                  type={"right"}
                  text="Details of the error"
                />
                <p className="sm">Error Details</p>
              </div>
              <div className={styles["blockItem__text"]}>
                <div className={styles["blockItem__text-log"]}>
                {
                    itemData?.err?.map((error, index) => {
                      return (
                        <p key={index} className="sm">
                          - {error.Key}: {JSON.stringify(error.Value)}
                        </p>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
