import React from "react";
import Tooltip from "components/tooltip/tooltip";
import { clockIcon, copyIcon, failIcon, tickCircle, file } from "base/SVG";
import styles from "./detail.module.scss";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export default function MainInfo({ itemData, tx, error }) {

  return (
    <div className={styles["block"]}>
      {
        (error == true || itemData?.success === false) ? (
          <div className={styles["block__inner"]} style={{ textAlign: 'center' }}>
            {file}
            <p style={{ marginBottom: '6px', fontSize: '18px', color: 'white', fontWeight: '600' }}>Invalid Transaction ID</p>
            <p 
            style={{
              fontSize: '14px',
              color: '#CACACA',
              width: "60%",
              margin: "0 auto",
            }}
            >We couldn't find any transaction matching the provided ID. Please double-check the transaction ID and try again.</p>
            <br />
            <button
              type="button"
              className={styles["error__btn"]}
              onClick={() => {
                window.location.href = '/app/transaction-lookup';
              }}
            >
              Go Back
            </button>
          </div>
        ) : (
          <>
            <div className={styles["block__head"]}>
              <h4>Transaction Details</h4>
            </div>
            <div className={styles["block__inner"]}>
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Transaction ID of the transaction" />
                  <p className="sm">Transaction ID</p>
                </div>
                <div className={styles["blockItem__info"]}>
                  <p className="sm">{tx}</p>
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(tx);
                      toast.success("Copied to clipboard", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                      });
                    }}
                  >
                    {copyIcon}
                  </button>
                </div>
              </div>
              {
                itemData?.transactionResult?.signer && (
                  <div className={styles["blockItem"]}>
                    <div className={styles["blockItem__title"]}>
                      <Tooltip type={"right"} text="Signer of the transaction" />
                      <p className="sm">Signer</p>
                    </div>
                    <div className={styles["blockItem__info"]}>
                      <p className="sm">{itemData?.transactionResult?.signer}</p>
                      <button
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(itemData?.transactionResult?.signer);
                          toast.success("Copied to clipboard", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          });
                        }}
                      >
                        {copyIcon}
                      </button>
                    </div>
                  </div>
                )
              }
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Status of the transaction" />
                  <p className="sm">Status</p>
                </div>
                <div className={styles["blockItem__status"]}>
                  {itemData?.transactionResult?.status === "succeeded" && (
                    <>
                      <span className={styles["success"]}>
                        {tickCircle}
                        Success
                      </span>
                    </>
                  )}{" "}
                  {itemData?.transactionResult?.status !== "succeeded" && (
                    <>
                      <span className={styles["failed"]}>
                        {failIcon}
                        {itemData?.transactionResult?.status.toUpperCase()}
                      </span>
                    </>
                  )}
                </div>
              </div>{" "}
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Time when the transaction was received" />
                  <p className="sm">Timestamp</p>
                </div>
                <div className={styles["blockItem__text"]}>
                  {clockIcon}
                  <p className="sm">
                    {itemData?.transactionResult?.receivedTs}
                  </p>
                </div>
              </div>{" "}
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Slot when the transaction was received" />
                  <p className="sm">Received Slot</p>
                </div>
                <div className={styles["blockItem__text"]}>
                  <p className="sm">{itemData?.transactionResult?.receivedSlot}</p>
                </div>
              </div>{" "}
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Priority Fee paid for the transaction" />
                  <p className="sm">Priority Fee</p>
                </div>
                <div className={styles["blockItem__text"]}>
                  <p className="sm">{itemData?.transactionResult?.prioFee} SOL</p>
                </div>
              </div>
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Tip paid for the transaction" />
                  <p className="sm">Tip</p>
                </div>
                <div className={styles["blockItem__text"]}>
                  <p className="sm">{itemData?.transactionResult?.tip} Lamports</p>
                </div>
              </div>
              <div className={styles["blockItem"]}>
                <div className={styles["blockItem__title"]}>
                  <Tooltip type={"right"} text="Whether the transaction was front-run protected" />
                  <p className="sm">Front-Running Protection</p>
                </div>
                <div className={styles["blockItem__status"]}>
                  {
                    itemData?.transactionResult?.frontRunningProtection == true ? (
                      <span className={styles["success"]}>
                        {tickCircle}
                        Enabled
                      </span>
                    ) : (
                      <span className={styles["failed"]}>
                        {failIcon}
                        Disabled
                      </span>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}
