import React, { useMemo, useRef, useState } from "react";
import styles from "./table.module.scss";
//import { tableList } from "./modul";
import {
  arrowBtnDown,
  chevronRight,
  chevronRightP,
  copyIcon,
  failIcon,
  tickCircle,
  tickIcon,
} from "base/SVG";
import { Pagination } from "components/pagination/pagination";
import DateInput from "components/date-input/date-input";
import { useNavigate } from "react-router-dom";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Table({ tableList, getNewData, startDate, endDate }) {
  const [form, setForm] = useState({
    startDate: startDate,
    endDate: endDate,
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const [activeList, setActiveList] = useState(tableList);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tableList]);

  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__action"]}>
        <h4>Transaction Lookup History</h4>
        <div className={styles["table__action-date"]}>
          <DateInput
            type={"start"}
            startDate={form?.startDate}
            endDate={form?.endDate}
            updateForm={updateForm}
            getNewData={getNewData}
            allowTimeInput={false}
            />
        </div>
      </div>

      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Received Timestamp</th>
                <th>Transaction ID</th>
                <th>Tip Paid</th>
                <th>Prio Fee</th>
                <th>Frontrunning Protection</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    No transaction history yet
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <TableRow item={item} key={index} />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
const TableRow = ({ item }) => {
  const copy_first = useRef(null);
  const copy_second = useRef(null);
  const clickHandler = (e) => {
    // If statement to prevent redirect if user clicks on copy button
    if (
      !copy_first?.current?.contains(e.target) &&
      !copy_second?.current?.contains(e.target)
    ) {
      window.location.href = `/app/transaction-lookup?tx=${item.tx}`;
    }

  };
  const navigate = useNavigate();
  return (
    <tr onClick={clickHandler}>
      <td>
        {item.receivedTs}
      </td>
      <td>
        <div className={styles["table__copy"]}>
          <span> {item.tx}</span>
          <button
            ref={copy_first}
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(item.tx);
              toast.success("Copied to clipboard", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }}
          >
            {copyIcon}
          </button>
        </div>
      </td>
      <td>{item.tip} Lamports</td>
      <td>{item.prioFee}</td>
      <td>
        <div className={styles["table__status"]}>
          {item.frontRunningProtection == true && (
            <>
              <span className={styles["success"]}>
                {tickCircle}
                Enabled
              </span>
            </>
          )}{" "}
          {item.frontRunningProtection == false && (
            <>
              <span className={styles["failed"]}>
                {failIcon}
                Disabled
              </span>
            </>
          )}
        </div>
      </td>
      <td>
        <button type="button">{chevronRightP}</button>
      </td>
    </tr>
  );
};
