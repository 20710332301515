import React from "react";
import styles from "./difference.module.scss";
import Spin from "./spin";
export default function Difference() {
  return (
    <div className={styles["difference"]}>
      <div className="auto__container">
        <div className={styles["difference__inner"]}>
          <div className={styles["difference__inner-content"]}>
            <h2>See the Difference Yourself</h2>
            <p className="big">
              Compare transaction confirmation speeds from our competitors and
              experience why NextBlock leads the pack.
            </p>
          </div>
          <Spin />
        </div>
      </div>
    </div>
  );
}
