import React, { useEffect, useState } from "react";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
} from "base/constants";
import {
  minLength,
  stripeCardExpirValidation,
  stripeCardNumberValidation,
  textWithSpacesOnly,
} from "base/validation";
import MaskedInput from "react-text-mask";
import Button from "components/primary-button/button";
import styles from "./payment-method.module.scss";
import Input from "components/input/input";
import { cardIcon } from "base/SVG";
import classNames from "classnames";
import CountrySelect from "components/contry-select/contry-select";
import { CountryList } from "base/country-modul";


export default function PaymentMethod({ user, setSelectedPlanLink, cardLink, planPrice, subscribeWBalance }) {

  const [errorCard, setErrorCard] = useState({
    fullNameError: "",
    cardNumberError: "",
    expDateError: "",
    cvvError: "",
  });

  const [checked, setChecked] = useState("balance");

  const nextStep = () => {
    console.log(checked);
    if (checked == "card") {
      window.open(cardLink, "_blank")
    } else {
      subscribeWBalance();
    }
  }

  useEffect(() => {
    if (user?.balance < (planPrice - user.subscription_price)) {
      console.log("here");
      setChecked("card");
    }
  }, [checked]);

  return (
    <div className={styles["form"]}>
      <h4>Choose Payment Option</h4>
      <form className={"form"} >
        <div className={styles["radio"]} onClick={() => setChecked("card")}>
          <label>
            <input type="radio" className={styles["radio-button__input"]} id="choice1-2" value="card" name="payment-type" checked={checked === "card"} onChange={() => setChecked("card")} />
            <span className={styles["radio-button__control"]}></span>
            <span className={styles["radio-button__label"]}>Pay with Credit Card</span>
          </label>
        </div>
        <div className={classNames(styles["radio"], { [styles["disabled"]]: user?.balance < (planPrice - user.subscription_price) })} onClick={() => user?.balance >= (planPrice - user.subscription_price) && setChecked("balance")}>
          <label>
            <input type="radio" className={styles["radio-button__input"]} id="choice1-2" value="balance" name="payment-type" checked={checked === "balance"} onChange={() => user?.balance >= (planPrice - user.subscription_price) && setChecked("balance")} disabled={user?.balance < (planPrice - user.subscription_price)} />
            <span className={styles["radio-button__control"]}></span>
            <span className={styles["radio-button__label"]}>Pay from Balance</span><span style={{ float: "right", fontSize: "14px", color: "rgba(202, 202, 202, 1)" }}>${user?.balance}</span>
          </label>
        </div>
        {user?.balance < (planPrice - user.subscription_price) && (
          <div className={styles["error-message"]}>
            Your balance is too low - please top up through the <a style={{color: '#ef4047', textDecoration: 'underline'}} href="../../../app/billing">Billing Page</a> or pay using Credit Card
          </div>
        )}
        {
          checked === "card" && (
            <div className={styles["error-message"]}>
              Credit Cards Payments are temporarily disabled. Please use the "Pay from Balance" option.
            </div>
          )
        }
      </form>
      <div className={styles["space"]}></div>
      <div className={styles["form__btns"]}>
        <Button onClick={() => setSelectedPlanLink("")} type={"gray"} text={"Cancel"} />
        <Button
          onClick={nextStep}
          type={"primary"}
          text={"Continue"}
          disabled={checked === "card" || user?.balance < (planPrice - user.subscription_price)}
        />
      </div>
    </div>
  );
}
