import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../auth.module.scss";
import InputEmail from "components/input-email/input-email";
import InputName from "components/input-name/input-name";
import { mailIcon, mailModalIcon, noteIcon } from "base/SVG";
import InputPass from "components/input-pass/input-pass";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// project import
import { SERVER_URL } from '../../config';

export default function SignUp() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  useEffect(() => {
    const password = form.password;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (passwordRegex.test(password)) {
      setPasswordWeak(false);
    } else {
      setPasswordWeak(true);
    }
  }, [form.password]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [hCaptchaToken, setHCaptchaToken] = useState('');

  const handleVerificationSuccess = (token, ekey) => {
    setHCaptchaToken(token);
  }

  const register = async (firstname, lastname, email, password) => {
    try {
      const response = await axios.post(SERVER_URL + '/api/auth/register', { firstname, lastname, email, password, hCaptchaToken });

      // Check if status code is 201
      if (response.status === 201) {
        // Set token in cookies that expires in 12 hours
        setSuccessfullyRegistered(true);

        // Redirect to login page
        //window.location.href = '../../auth/sign-in';
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
    }
    catch (error) {
      toast.error("Error occurred: " + error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const { firstname, lastname, email, password } = form;

    if (!passwordWeak) {
      register(firstname, lastname, email, password);
    }
    setSubmitting(false);
  }

  return (
    <>
      {
        successfullyRegistered && (
          <div className={styles["auth__inner-head"]}>
            <div className={styles["auth__inner-icon"]}>{mailModalIcon}</div>
            <h3>Successfully Registered</h3>
            <p>
              Please check your email for the verification link. If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.
            </p>
            <br />
            <div className={styles["auth__inner-btn"]}>
              <Button
                onClick={() => navigate("/auth/sign-in")}
                type={"gray"}
                text={"Go to Login"}
              />
            </div>
          </div>
        )
      }
      {
        !successfullyRegistered && (
          <>
            <ToastContainer />
            <div className={styles["auth__inner-head"]}>
              <h3>Create your Account</h3>
              <p>
                Already have an account? <Link to="sign-in">Log In</Link>
              </p>
            </div>

            <form>
              <InputName
                firstNameLabel={"First Name"}
                lastNameLabel={"Last Name"}
                firstNamePlaceholder={"Enter your first name"}
                lastNamePlaceholder={"Enter your last name"}
                onFirstNameChange={(e) => {
                  updateForm({ firstname: e.target.value });
                }}
                onLastNameChange={(e) => {
                  updateForm({ lastname: e.target.value });
                }}
                firstNameIcon={noteIcon}
                lastNameIcon={noteIcon}
              />
              <InputEmail
                label={"Email Address"}
                placeholder={"Enter your email"}
                onChange={(e) => {
                  updateForm({ email: e.target.value });
                }}
                icon={mailIcon}
                value={form.email}
              />
              <div className={styles["space"]}></div>
              <InputPass
                label={"Password"}
                placeholder={"Enter your password"}
                onChange={(e) => {
                  updateForm({ password: e.target.value });
                }}
                icon={mailIcon}
                value={form.password}
              />
              {
                passwordWeak && (
                  <p className={styles["note"]}>
                    {noteIcon}
                    Password Strength: At least eight characters, one uppercase, one
                    lowercase, a number and a special character
                  </p>
                )
              }
              <div className={styles["captcha-container"]}>
                <HCaptcha
                  sitekey="3b3ceb76-6488-405a-baaa-42567dce94ca"
                  theme="dark"
                  onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />
              </div>
              <div className={styles["auth__inner-btn"]}>
                <Button
                  text="Sign Up"
                  type={"primary"}
                  onClick={(e) => handleSubmit(e)}
                  disabled={isSubmitting}
                />
              </div>
            </form>
            <div className={styles["auth__inner-foot"]}>
              <p>
                By signing up, you agree to our <Link to="">Terms of Service </Link>{" "}
                and
                <Link to=""> Privacy Policy.</Link>
              </p>
            </div>
          </>
        )
      }
      
    </>
  );
}
