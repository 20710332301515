import { copyIcon, dotsIcon, eyeIcon, plusIcon, noteIcon } from "base/SVG";

import React, { useEffect, useState } from "react";
import styles from "./billing.module.scss";
import Button from "components/primary-button/button";
import { AnimatePresence } from "framer-motion";
import Modal from "App/components/modal/modal";
import { Link, useNavigate } from "react-router-dom";
import Table from "./components/table/table";
import CancelSubscription from "./components/cancel-subscription";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../config';


export default function Billing({ setPaymentMethod }) {
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();
  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };

  const [tableList, setTableList] = useState([]);
  const [billingDetails, setBillingDetails] = useState({});

  const [loaded, setLoaded] = useState(false);

  const cancelFunction = () => {
    // Make a post to /api/auth/cancel-subscription
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    axios.post(SERVER_URL + '/api/auth/cancel-subscription', {}, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setModal(null);

        getBillingDetails();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setModal(null);
      });
  };

  useEffect(() => {
    getBillingDetails();
  }, []);

  const getBillingDetails = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setBillingDetails(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const reactivatePlan = () => {
    // Make a post to /api/auth/reactivate-subscription
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    axios.post(SERVER_URL + '/api/billing/reactivate-subscription', {}, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        getBillingDetails();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setModal(null);
      });
  };

  useEffect(() => {

    if (Object.keys(billingDetails).length === 0) {
      // Wait for billing details to load
      return;
    }

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/billing/transaction-history', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setTableList(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  }, [billingDetails]);
  
  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }

    date = new Date(date);
    return date.toLocaleDateString('en-US');
  };

  const addMonths = (date, months) => {

    if (!date) {
      return "";
    }

    date = new Date(date);

    date.setMonth(date.getMonth() + months);
    return date.toLocaleDateString('en-US');
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["bill"]}>
        <div className={styles["container"]}>
          <div className={styles["bill__inner"]}>
            <div className={styles["bill__head"]}>
              <div className={styles["bill__head-text"]}>
                <h4>Billing Plan</h4>
                <p className="sm">
                  View payment history, manage subscriptions, and control your
                  billing preferences.
                </p>
              </div>
              <div className={styles["bill__head-btns"]} style={{ textAlign: "center", lineHeight: "50px" }}>
                {billingDetails.subscription_plan !== "Free" && billingDetails.unsubscribed_since == undefined && (
                  <>
                    <Link onClick={() => setModal("cancel")} >
                      Cancel Subscription
                    </Link>
                  </>
                )}
                {
                  billingDetails.unsubscribed_since != undefined && billingDetails.payment_method == "Wallet Balance" &&
                  <>
                    <Link onClick={() => reactivatePlan()} >
                      Reactivate Plan
                    </Link>
                  </>
                }
                <Button
                  onClick={() => navigate("/app/manage-plan")}
                  type={"primary"}
                  text="Manage Plan"
                />

              </div>
            </div>
            {
                billingDetails.next_billing_cycle_subscription_plan_name != undefined && (
                  <>
                    <p className={styles["note"]}>
                      {noteIcon}
                      At the end of your current billing cycle, your plan will be automatically changed to the {billingDetails.next_billing_cycle_subscription_plan_name} Plan
                    </p>
                    <br />
                  </>
                )
            }
            <div className={styles["bill__info"]}>
              <div className={styles["bill__info-item"]}>
                <h6>{billingDetails.subscription_plan} Plan</h6>
                <div className={styles["bill__info-item-price"]}>
                  <h4 className="big">${billingDetails.subscription_price}</h4>
                  <p>/{billingDetails.subscription_runtime_name}</p>
                </div>
              </div>{" "}
              <div className={styles["bill__info-item"]}>
                <div className={styles["bill__info-item-title"]}>
                  <p>Billing Period</p>
                </div>
                {
                    (billingDetails.subscription_plan !== "Free" && billingDetails.subscription_runtime_name == "Monthly") && (
                      <h5>{removeTimestamp(billingDetails.last_subscription_payment)} - {removeTimestamp(addMonths(billingDetails.last_subscription_payment, 1))}</h5>
                    )
                }
                {
                    (billingDetails.subscription_plan !== "Free" && billingDetails.subscription_runtime_name == "Yearly") && (
                      <h5>{removeTimestamp(billingDetails.last_subscription_payment)} - {removeTimestamp(addMonths(billingDetails.last_subscription_payment, 12))}</h5>
                    )
                }
                {
                    (billingDetails.subscription_plan == "Free") && (
                      <h5>-</h5>
                    )
                }
              </div>{" "}
              <div className={styles["bill__info-flex"]}>
                <div className={styles["bill__info-flex-text"]}>
                  <p>Payment Method</p>
                  {
                    billingDetails.subscription_plan !== "Free" ? (
                      <h5>{billingDetails.payment_method}</h5>
                    ) : (
                      <h5>-</h5>
                    )
                  }
                </div>
                <div className={styles["bill__head-btns"]} style={{ textAlign: "center", lineHeight: "50px" }}>
                  {
                    billingDetails.payment_method == "Credit Card" && billingDetails.subscription_plan !== "Free" &&
                      <>
                        <button type="button" onClick={() => window.location.href = billingDetails.update_payment_method_link} style={{padding: "0"}}>
                          Change Payment Method
                        </button>
                        <br />
                      </>
                  }
                  <button type="button" onClick={() => document.getElementById("balanceBtn").click()} >
                    Top Up Balance
                  </button>
                </div>

              </div>
            </div>
            <Table tableList={tableList} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === "cancel" && (
          <Modal closeFunc={closeFunc}>
            <CancelSubscription setModal={setModal} cancelFunction={cancelFunction} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
