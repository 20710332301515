import React, { useEffect, useRef, useState } from "react";
import styles from "./difference.module.scss";
import { animate, motion } from "framer-motion";
import Button from "components/primary-button/button";
import { Link } from "react-router-dom";
import icon1 from "assets/images/types/1.png";
import icon2 from "assets/images/types/2.png";
import icon3 from "assets/images/types/3.png";
import icon4 from "assets/images/types/4.png";
import icon5 from "assets/images/types/5.png";
import classNames from "classnames";

const spinList = [
  {
    id: "1",
    icon: icon1,
    name: "Nextblock",
    time: 0.4,
    active: true,
  },
  {
    id: "2",
    icon: icon2,
    name: "Helius",
    time: 1.2,
  },
  {
    id: "3",
    icon: icon3,
    name: "bloXroute",
    time: 2.0,
  },
  {
    id: "4",
    icon: icon4,
    name: "Quicknode",
    time: 2.4,
  },
  {
    id: "5",
    icon: icon5,
    name: "Generic RPC",
    time: 4.0,
  },
];

export default function Spin() {
  const [firstAct, setFirstAct] = useState(false);
  const [spanKey, setSpanKey] = useState(0);

  return (
    <div className={styles["spin"]}>
      <div className={styles["spin__inner"]}>
        <div className={styles["spin__inner-box"]}>
          {spinList.map((item, index) => {
            return <SpinItem spanKey={spanKey} {...item} key={item.id} />;
          })}
        </div>
        <div className={styles["spin__inner-action"]}>
          {!firstAct ? (
            <Button
              type={"primary"}
              text="Test Transaction Speed"
              onClick={() => {
                setFirstAct(true);
                setSpanKey(spanKey + 1);
              }}
            />
          ) : (
            <Button
              onClick={() => {
                setFirstAct(false);
                setSpanKey(spanKey + 1);
              }}
              type={"gray"}
              text="Reset Transaction Timer"
            />
          )}
        </div>
      </div>
      <div className={styles["spin__tool"]}>
        <p>
          Ready to leave the competition in the dust?{" "}
          <Link to="/auth">Get started with NextBlock today.</Link>
        </p>
      </div>
    </div>
  );
}

const SpinItem = (props) => {
  function Counter({ from, to }) {
    const [numbers, setNumbers] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const steps = 20; // Number of steps for the spin effect
      const increment = (to - from) / steps;

      // Generate numbers for the spin sequence
      const generatedNumbers = Array.from({ length: steps + 1 }, (_, i) =>
        (from + increment * i).toFixed(1)
      );
      setNumbers(generatedNumbers);

      // Start spinning effect
      let index = 0;
      const interval = setInterval(() => {
        setCurrentIndex((prev) => (prev < steps ? prev + 1 : prev));
        index++;
        if (index > steps) clearInterval(interval);
      }, (props.time * 1000) / steps);

      return () => clearInterval(interval);
    }, [from, to, props.time]);

    return (
      <motion.div className={styles["spinItem__counter-inner"]}>
        <motion.div
          style={{
            position: "relative",
          }}
          animate={{ y: `-${currentIndex * 46}px` }}
          transition={{ duration: 0.2, ease: "linear" }}
        >
          {numbers.map((num, index) => (
            <div className={styles["spinItem__counter-item"]} key={index} style={{ height: "46px" }}>
              {num}s
            </div>
          ))}
        </motion.div>
      </motion.div>
    );
  }

  return (
    <div className={styles["spinItem"]}>
      <div className={styles["spinItem__inner"]}>
        <div className={styles["spinItem__inner-title"]}>
          <img src={props.icon} alt={props.name} />
          <p>{props.name}</p>
        </div>
        <div className={styles["spinItem__inner-progress"]}>
          <motion.span
            key={props.spanKey + 1}
            animate={{ width: "100%" }}
            transition={{ duration: props.time, ease: "easeInOut" }}
          ></motion.span>
        </div>
      </div>
      <div
        className={classNames(styles["spinItem__counter"], {
          [styles.active]: props.active,
        })}
      >
        <Counter from={0} to={props.time} />
      </div>
    </div>
  );
};
