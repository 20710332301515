import { chevronLeftP } from "base/SVG";
import React from "react";
import styles from "./detail.module.scss";
import { Link } from "react-router-dom";
import MainInfo from "./main-info";
import AddInfo from "./add-info";
export default function Detail({ error, itemData, tx }) {

  console.log("itemData", itemData);

  return (
    <div className={styles["detail"]}>
      <Link to="/app/transaction-lookup" className={styles["detail__back"]}>
        <div className={styles["detail__back-icon"]}>{chevronLeftP}</div>
        <span>Back</span>
      </Link>
      <div className={styles["detail__inner"]}>
        <MainInfo error={error} itemData={itemData} tx={tx} />
        {
          itemData?.transactionResult?.simulationResults?.map((item, index) => 
            {
            return (
              <>
                <AddInfo key={index} itemData={item} id={index} />
                <br />
              </>
            )
            })
        }
      </div>
    </div>
  )
}
