import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import SelectSecondary from "components/select-secondary/select-secondary";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerablesJS);

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Chart({
  timeSeriesMetrics,
  grouping,
  activeChart,
}) {
  const [dateArr, setDateArr] = useState([
    "23 May 2023",
    "23 Jun 2023",
    "23 Jul 2023",
    "23 Aug 2023",
    "23 Sep 2023",
    "23 Oct 2023",
    "23 Nov 2023",
    "23 Dec 2023",
    "23 Jan 2023",
    "23 Feb 2023",
    "23 Mar 2023",
    "23 Apr 2023",
  ]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [labels, setLabels] = useState();

  let chartColor;

  if (activeChart === "transactions") {
    chartColor = "#FF696F";
  } else if (activeChart === "transactionsLanded") {
    chartColor = "#FFD464";
  } else if (activeChart === "successRate") {
    chartColor = "#7DFFB7";
  } else if (activeChart === "medianLandingTime") {
    chartColor = "#60E4FC";
  }
  console.log("activeChart", activeChart);
  console.log("chartColor", chartColor);

  const [chartDivStyle, setChartDivStyle] = useState({});

  const formatTimestamp = (date) => {
    console.log(grouping);

    if (grouping == "5m0s") {
      return new Date(date).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (grouping == "10m0s") {
      return new Date(date).toLocaleTimeString("en-US", {
        hour: "2-digit",
      });
    } else if (grouping == "1h0m0s") {
      // Show only time
      return new Date(date).toLocaleTimeString("en-US", {
        day: "2-digit",
        hour: "2-digit",
      });
    } else {
      //  if (grouping == "24h0m0s")
      return new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    }
  };

  useEffect(() => {
    setData(timeSeriesMetrics.map((item) => item.transactionCount));

    setData2(timeSeriesMetrics.map((item) => item.transactionsLandedCount));

    setData3(timeSeriesMetrics.map((item) => item.transactionSuccessRate));

    //setData4(timeSeriesMetrics.map((item) => item.medianLandingTime));
    //setdata4 but filter out values larger then 25 (if above 25 probably an error)
    setData4(
      timeSeriesMetrics.map((item) =>
        item.medianLandingTime > 25 ? 25 : item.medianLandingTime
      )
    );

    setLabels(timeSeriesMetrics.map((item) => formatTimestamp(item.timestamp)));

    if (timeSeriesMetrics.length == 0) {
      setChartDivStyle({
        filter: "blur(5px)",
        mozFilter: "blur(5px)",
        webkitFilter: "blur(5px)",
        oFilter: "blur(5px)",
        msFilter: "blur(5px)",
      });
    }
  }, [timeSeriesMetrics]);

  const formatNumberComma = (n) => {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    // layout: {
    //   padding: {
    //     top: 28,
    //   },
    // },
    elements: {
      point: {
        borderColor: "#ffffff",
        borderWidth: 0,
        hoverBorderWidth: 2,
      },
      //   line: {
      //     tension: 0.1,
      //   },
    },
    scales: {
      y: {
        border: {
          display: false,
          //   color: "#EBEBEF",
        },
        ticks: {
          maxTicksLimit: 7,
          // display: false,
          //   padding: 12,
          callback: (data) => {
            if (data > 1000) {
              return data / 1000 + "k";
            } else {
              return data;
            }
          },
          font: {
            family: "Plus Jakarta Sans",
            size: 11,
            lineHeight: "16px",
            weight: 400,
          },
          color: "#ffffff",
        },
        grid: {
          // drawTicks: false,
          color: "#ffffff",
          display: false,
        },
        // border: {
        //   dash: [1, 50.5],
        // },
        // beginAtZero: true,
      },
      x: {
        border: {
          display: false,
          color: "#EBEBEF",
        },

        ticks: {
          //   display: false,
          //   padding: 12,
          font: {
            family: "Plus Jakarta Sans",
            size: 11,
            lineHeight: "16px",
            weight: 400,
          },
          color: "#ffffff",
        },
        grid: {
          // drawTicks: false,
          display: false,
        },
        // beginAtZero: true,
      },
    },
  });
  const [dataInfo, setDataInfo] = useState({
    labels,
    datasets: [
      {
        type: "line",
        data: data,
        borderColor: chartColor,
        borderWidth: 2,
        // hoverBorderWidth: 2,
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",
        pointRadius: 7,
        pointHoverRadius: 7,
        pointStyle: "rect",
        pointHoverBackgroundColor: "#000000",
        pointHoverBorderColor: "#ffffff",
      },
    ],
  });

  useEffect(() => {
    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
      // layout: {
      //   padding: {
      //     top: 28,
      //   },
      // },
      elements: {
        point: {
          borderColor: "#ffffff",
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
        line: {
          tension: 0.5,
        },
      },
      scales: {
        y: {
          border: {
            display: false,
            //   color: "#EBEBEF",
          },
          ticks: {
            // maxTicksLimit: 7,
            // display: false,
            //   padding: 12,
            callback: (data) => {
              if (data > 1000) {
                return data / 1000 + "k";
              } else {
                return data;
              }
            },
            font: {
              family: "Inter",
              size: 12,
              lineHeight: "16px",
              weight: 400,
            },
            color: "#AEAEAE",
          },
          grid: {
            // drawTicks: false,
            color: "#ffffff",
            display: false,
          },
          // border: {
          //   dash: [1, 50.5],
          // },
          // beginAtZero: true,
        },
        x: {
          border: {
            display: false,
            color: "#EBEBEF",
          },

          ticks: {
            //   display: false,
            //   padding: 12,
            font: {
              family: "Inter",
              size: 12,
              lineHeight: "16px",
              weight: 400,
            },
            color: "#AEAEAE",
          },
          grid: {
            // drawTicks: false,
            display: true,
            color: "#181818",
          },
          // beginAtZero: true,
        },
      },
    });
    /*setDataInfo({
      labels,
      datasets: [
        {
          type: "line",
          data: data,
          borderColor: "#FF696F",
          borderWidth: 2,
          // hoverBorderWidth: 2,
          pointBackgroundColor: "rgba(0,0,0,0)",
          pointBorderColor: "rgba(0,0,0,0)",
          pointRadius: 0,
          pointHoverRadius: 0,

          pointHoverBackgroundColor: "#000000",
          pointHoverBorderColor: "#ffffff",
        },
        {
          type: "line",
          data: data2,
          borderColor: "#60E4FC",
          borderWidth: 2,
          // hoverBorderWidth: 2,
          pointBackgroundColor: "rgba(0,0,0,0)",
          pointBorderColor: "rgba(0,0,0,0)",
          pointRadius: 0,
          pointHoverRadius: 0,

          pointHoverBackgroundColor: "#000000",
          pointHoverBorderColor: "#ffffff",
        },
      ],
    });*/
    if (activeChart == "transactions") {
      setDataInfo({
        labels,
        datasets: [
          {
            type: "line",
            data: data,
            borderColor: chartColor,
            borderWidth: 2,
            // hoverBorderWidth: 2,
            pointBackgroundColor: "rgba(0,0,0,0)",
            pointBorderColor: "rgba(0,0,0,0)",
            pointRadius: 0,//7 to have a pointer on hover. 0 to remove it
            pointHoverRadius: 7,
            pointStyle: "rect",
            pointHoverBackgroundColor: "#000000",
            pointHoverBorderColor: "#ffffff",
          },
        ],
      });
    } else if(activeChart == "transactionsLanded") {
      setDataInfo({
        labels,
        datasets: [
          {
            type: "line",
            data: data2,
            borderColor: chartColor,
            borderWidth: 2,
            // hoverBorderWidth: 2,
            pointBackgroundColor: "rgba(0,0,0,0)",
            pointBorderColor: "rgba(0,0,0,0)",
            pointRadius: 0,//7 to have a pointer on hover. 0 to remove it
            pointHoverRadius: 7,
            pointStyle: "rect",
            pointHoverBackgroundColor: "#000000",
            pointHoverBorderColor: "#ffffff",
          },
        ],
      });
    } else if(activeChart == "successRate") {
      setDataInfo({
        labels,
        datasets: [
          {
            type: "line",
            data: data3,
            borderColor: chartColor,
            borderWidth: 2,
            // hoverBorderWidth: 2,
            pointBackgroundColor: "rgba(0,0,0,0)",
            pointBorderColor: "rgba(0,0,0,0)",
            pointRadius: 0,//7 to have a pointer on hover. 0 to remove it
            pointHoverRadius: 7,
            pointStyle: "rect",
            pointHoverBackgroundColor: "#000000",
            pointHoverBorderColor: "#ffffff",
          },
        ],
      });
    } else if(activeChart == "medianLandingTime") {
      setDataInfo({
        labels,
        datasets: [
          {
            type: "line",
            data: data4,
            borderColor: chartColor,
            borderWidth: 2,
            // hoverBorderWidth: 2,
            pointBackgroundColor: "rgba(0,0,0,0)",
            pointBorderColor: "rgba(0,0,0,0)",
            pointRadius: 0,//7 to have a pointer on hover. 0 to remove it
            pointHoverRadius: 7,
            pointStyle: "rect",
            pointHoverBackgroundColor: "#000000",
            pointHoverBorderColor: "#ffffff",
          },
        ],
      });
    }

  }, [data, data2, data3, data4, activeChart]);

  return (
    <>
      <div className={styles["chart"]}>
        {timeSeriesMetrics.length == 0 ? (
          <div className={styles["chart__empty"]}>
            <h5
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                margin: "0",
                width: "110%",
              }}
            >
              No Data Available Yet
            </h5>
            <p
              style={{
                width: "75%",
                position: "absolute",
                top: "61%",
                //left: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              It looks like you haven’t generated any activity to display here.
              Start using our services to display your usage activity.
            </p>
          </div>
        ) : null}
        <div className={styles["chart__labels"]}>
          <div className={styles["chart__label"]}>
            <span style={{ background: chartColor }}></span>
            {activeChart === "transactions" && "Transactions sent"}
            {activeChart === "transactionsLanded" && "Transactions Landed"}
            {activeChart === "successRate" && "Success Rate"}
            {activeChart === "medianLandingTime" && "Median Landing Time"}
          </div>
        </div>
        {/*<div className={styles["chart__select"]}>
          <SelectSecondary
            onChange={(data) => {
              setData(dataList[data.index]);
              setData2(dataList[data.index2]);
            }}
            list={periodList}
            selected={periodList[0]}
          />
        </div>*/}
        <div className={styles["chart__inner"]} style={chartDivStyle}>
          <Line options={options} data={dataInfo} />
        </div>
      </div>
    </>
  );
}
