import InputEmail from "components/input-email/input-email";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../auth.module.scss";
import { mailIcon, noteIcon } from "base/SVG";
import InputPass from "components/input-pass/input-pass";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';


export default function SignIn() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const [submitting, setSubmitting] = useState(false);

  const login = async (email, password) => {
    try {
      const response = await axios.post(SERVER_URL + '/api/auth/login', { email, password });

      // Check if status code is 200
      if (response.status === 200) {
        // Set token in cookies that expires in 30 days
        document.cookie = `token=${response.data.token};max-age=2592000;path=/`;

        // Redirect to dashboard
        window.location.href = '../../app';
      } else {

        if (response.data.message == "Please verify your email") {
          navigate('../../../auth/mail?email=' + email);
        }

        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
    }
    catch (error) {

      if (error.response.data.message == "Please verify your email") {
        navigate('../../../auth/mail?email=' + email);
      }

      toast.error("Invalid email or password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const { email, password } = form;

    login(email, password);
    setSubmitting(false);
  }

  useEffect(() => {
    // Check if user is logged in already
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (token) {
      window.location.href = '../../app';
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className={styles["auth__inner-head"]}>
        <h3>Sign in to Nextblock</h3>
        <p>
          Don’t have an account? <Link to="/auth">Sign up</Link>
        </p>
      </div>

      <InputEmail
        label={"Email Address"}
        placeholder={"Enter your email"}
        onChange={(e) => {
          updateForm({ email: e.target.value });
        }}
        icon={mailIcon}
        value={form.email}
      />
      <div className={styles["space"]}></div>
      <InputPass
        label={"Password"}
        placeholder={"Enter your password"}
        onChange={(e) => {
          updateForm({ password: e.target.value });
        }}
        icon={mailIcon}
        value={form.password}
      />
      <div className={styles["auth__inner-forget"]}>
        <Link to="/auth/forget-password">Forgot Password?</Link>
      </div>
      <div className={styles["auth__inner-btn"]}>
        <Button
          onClick={(e) => handleSubmit(e)}
          text="Sign In"
          type={"primary"}
          disabled={submitting}
        />
      </div>
    </>
  );
}
