import { mailModalIcon } from "base/SVG";
import React from "react";
import styles from "../auth.module.scss";
import Button from "components/primary-button/button";
import { useNavigate } from "react-router-dom";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';

export default function Mail() {

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const sendVerificationEmail = async () => {
    axios.post(SERVER_URL + '/api/auth/resend-email-verify', { email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    }).catch((error) => {
      toast.error("Error: " + error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    );
  };

  const navigate = useNavigate();
  return (
    <>
      <ToastContainer />
      <div className={styles["auth__inner-icon"]}>{mailModalIcon}</div>
      <div className={styles["auth__inner-mail"]}>
        <h3>You’ve got mail</h3>
        <p>
          We just sent a verification link to your email, proceed with the link
          to create a new password.
        </p>
      </div>
      <div className={styles["auth__inner-btn"]}>
        <Button
          onClick={sendVerificationEmail}
          type={"primary"}
          text={"Resend Verification Email"}
        />
        <br />
        <Button
          onClick={() => navigate("/auth/sign-in")}
          type={"gray"}
          text={"Go back to Login"}
        />
      </div>
    </>
  );
}
