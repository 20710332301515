import React from "react";
import styles from "./info.module.scss";
import { chevronRight, counsDollarIcon, dateIcon } from "base/SVG";

export default function Info({ usageData, billingDetails }) {

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }

    date = new Date(date);
    return date.toLocaleDateString('en-US');
  };

  const addMonths = (date, months) => {

    if (!date) {
      return "";
    }

    date = new Date(date);

    date.setMonth(date.getMonth() + months);
    return date.toLocaleDateString('en-US');
  };

  const infoList = [
    {
      id: "1",
      icon: counsDollarIcon,
      title: "Transactions Sent",
      number: usageData.totalTransactions,
    },
    {
      id: "2",
      icon: counsDollarIcon,
      title: "Median Landing Time",
      number: usageData.medianLandingTime + " Slots",
    },
  ];

  if (billingDetails.subscription_runtime_name == "Monthly") {
    infoList.push({
      id: "3",
      icon: dateIcon,
      title: "Billing Cycle",
      startDate: billingDetails.last_subscription_payment ? removeTimestamp(billingDetails.last_subscription_payment) : "-",
      endDate: billingDetails.last_subscription_payment ? removeTimestamp(addMonths(billingDetails.last_subscription_payment, 1)) : "-",
    });
  } else if (billingDetails.subscription_runtime_name == "Yearly") {
    infoList.push({
      id: "3",
      icon: dateIcon,
      title: "Billing Cycle",
      startDate: billingDetails.last_subscription_payment ? removeTimestamp(billingDetails.last_subscription_payment) : "-",
      endDate: billingDetails.last_subscription_payment ? removeTimestamp(addMonths(billingDetails.last_subscription_payment, 12)) : "-",
    });
  }

  return (
    <div className={styles["info"]}>
      {infoList.map((item, index) => {
        return <InfoItem {...item} key={index} />;
      })}
    </div>
  );
}
const InfoItem = (props) => {
  return (
    <div className={styles["infoItem"]}>
      <div className={styles["infoItem__icon"]}>{props.icon}</div>
      <div className={styles["infoItem__content"]}>
        <p className="sm">{props.title}</p>
        {props.number && (
          <div className={styles["infoItem__content-number"]}>
            {props.number}
          </div>
        )}{" "}
        {props.startDate && (
          <div className={styles["infoItem__content-date"]}>
            {props.startDate} {chevronRight} {props.endDate}
          </div>
        )}
      </div>
    </div>
  );
};
