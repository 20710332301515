import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";

import Lookup from "./components/lookup/lookup";
import Detail from "./components/detail/detail";
export default function TransactionLookup({ setPaymentMethod }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [tableList, setTableList] = useState([]);	

  const [itemData, setItemData] = useState();

  const [modal, setModal] = useState(null);
  const navigate = useNavigate();
  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const tx = urlParams.get('tx');

  const [apiList, setApiList] = useState([]);

  const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));

  const getApiKeys = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/endpoint/api-keys', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setApiList(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  useEffect(() => {
    getApiKeys();
  }, [tx]);

  useEffect(() => {
    setLoaded(false);

    if (!tx) {
      getNewData("", "");
    } else {
        axios
        .get(SERVER_URL + "/api/transaction-lookup?tx=" + tx, {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setItemData(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoaded(true);
          setError(true);

        });

    }
  }, [apiList]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const getNewData = (startDate, endDate) => {
    setLoaded(false);


    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    
    // Convert date to DD/MM/YYYY format if not empty/null
    if (startDate) {
      setStartDate(startDate);
      startDate = new Date(startDate).toISOString().split('T')[0];
    }
    if (endDate) {
      setEndDate(endDate);
      endDate = new Date(endDate).toISOString().split('T')[0];
    }

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/transaction-lookup/history?&startDate=' + startDate + '&endDate=' + endDate, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setTableList(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoaded(true);
          setError(true);
        });
    }

  };

  if (!loaded) {
    return (
      <>
        <ToastContainer />
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      </>
    );
  }

  if (tx) {
    return (
      <>
        <ToastContainer />
        <Detail error={error} itemData={itemData} tx={tx} />;
      </>
    )
  } else {
    return (
      <>
        <ToastContainer />
        <Lookup tableList={tableList} apiList={apiList} getNewData={getNewData} startDate={startDate} endDate={endDate} />
      </>
    )
  }

}
