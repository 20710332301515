import InputEmail from "components/input-email/input-email";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../auth.module.scss";
import InputPass from "components/input-pass/input-pass";
import { lockIcon, noteIcon } from "base/SVG";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';


export default function VerifyEmail() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const [successfullyVerified, setSuccessfullyVerified] = useState(false);

  const verifyEmail = async () => {

    axios.post(SERVER_URL + '/api/auth/verify-email', {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.status === 200) {
        document.cookie = `token=${response.data.token};max-age=2592000;path=/`;

        setSuccessfullyVerified(true);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    }).catch((error) => {
      toast.error("Error: " + error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    );
  }

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      {
        successfullyVerified ?
          <div className={styles["auth__inner-head"]}>
            <h3>Email Successfully Verified!</h3>
            <Button
              text="Go to Dashboard"
              type={"primary"}
              onClick={() => window.location.href = '../../../app'}
              style={{margin: "0 auto"}}
            />
          </div>
          :
          <div className={styles["auth__inner-head"]}>
            <h3>Verifying Email...</h3>
            <BarLoader
              color={"#26cead"}
              cssOverride={{
                display: "block",
                margin: "auto auto",
                borderColor: "red",
              }}
              size={150}
            />
          </div>
      }
    </>
  );
}
