import React, { useEffect, useState } from "react";
import Table from "./table/table";
import Input from "components/input/input";
import Button from "components/primary-button/button";
import styles from "../../transaction-lookup.module.scss";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { copyIcon, dotsIcon, eyeIcon, plusIcon, noteIcon } from "base/SVG";

export default function Lookup({ tableList, apiList, getNewData, startDate, endDate }) {

  const [searchValue, setSearchValue] = useState("");

  // Catch when user presses enter
  const handleKeyDown = (event) => {
    
    if (event.key === 'Enter') {

      if (apiList.length < 1) {
        toast.error("Please create an API key to access the transaction lookup", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }

      window.location.href = `../../app/transaction-lookup?tx=${searchValue}`
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [searchValue])

  return (
    <div className={styles["lookup"]}>
      <div className={styles["container"]}>
        <div className={styles["lookup__inner"]}>
          <div className={styles["lookup__head"]}>
            <div className={styles["lookup__head-text"]}>
              <h4>Transaction Lookup</h4>
              <p className="sm">
                Search and review detailed information about any transaction
                processed through NextBlock.
              </p>
              {
                apiList.length < 1 &&
                <>
                  <br />
                  <p className={styles["note"]}>
                    {noteIcon}
                    Please create an API key to access the transaction lookup
                  </p>
                </>
              }
            </div>
          </div>
          <div className={styles["lookup__search"]}>
            <div className={styles["input"]}>
              <Input
                placeholder={"e.g., TxID: 4e3g57v... or Wallet: A1bC2dE3Fg..."}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>

            <div className={styles["btn"]}>
              <Button
                text="Search"
                type={"primary"} 
                onClick={() => {window.location.href = `../../app/transaction-lookup?tx=${searchValue}`}}
                disabled={apiList.length < 1}
              />
            </div>
          </div>

          <Table tableList={tableList} getNewData={getNewData} startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </div>
  );
}
