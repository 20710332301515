import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import SelectSecondary from "components/select-secondary/select-secondary";
import Chart from "./chart";
import {
  medianIcon,
  successRateIcon,
  transactionLandIcon,
  transactionSentIcon,
} from "base/SVG";

export default function Summary({
  usageData,
  getUsageData,
  periodList,
  selectedPeriodList,
  setSelectedPeriodList,
}) {
  const updatePeriod = (item) => {
    setSelectedPeriodList(item);

    let startDate = "";
    let endDate = "";

    switch (item.id) {
      case "1":
        startDate = new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .split("T")[0];
        endDate = new Date().toISOString().split("T")[0];
        break;
      case "2":
        startDate = new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0];
        endDate = new Date().toISOString().split("T")[0];
        break;
      case "3":
        startDate = new Date(new Date().setMonth(new Date().getMonth() - 3))
          .toISOString()
          .split("T")[0];
        endDate = new Date().toISOString().split("T")[0];

        // Minus 1 day so it does not exceed 90 days
        endDate = new Date(
          new Date(endDate).setDate(new Date(endDate).getDate() - 2)
        )
          .toISOString()
          .split("T")[0];

        break;
    }

    getUsageData(startDate, endDate);
  };

  const [activeChart, setActiveChart] = useState("transactions"); // transactions or landingTime
  
  return (
    <div className={styles["summary"]}>
      <div className={styles["summary__head"]}>
        <h4>Usage Summary</h4>
        <p className="sm">
          Get a quick overview of your transaction volume and fees over the
          selected period.
        </p>
      </div>
      <div className={styles["summary__inner"]}>
        <div className={styles["overview"]}>
          <div className={styles["overview__head"]}>
            <h4>Usage Overview</h4>
            <div className={styles["overview__select"]}>
              <SelectSecondary
                list={periodList}
                selected={selectedPeriodList}
                onChange={updatePeriod}
              />
            </div>

          </div>
          <p>Click on the cards to view more details</p>
          <br />
          <div className={styles["overview__body"]}>
            <div
              className={styles["overviewItem"]}
              onClick={() => setActiveChart("transactions")}
            >
              <div
                style={{ background: "#FF696F" }}
                className={styles["overviewItem__icon"]}
              >
                {transactionSentIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Transactions Sent</p>
                <h4>{usageData.totalTransactions}</h4>
              </div>
            </div>{" "}
            <div
              className={styles["overviewItem"]}
              onClick={() => setActiveChart("transactionsLanded")}
            >
              <div
                className={styles["overviewItem__icon"]}
                style={{ background: "#FFD464" }}
              >
                {transactionLandIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Transaction Landed</p>
                <h4>{usageData.totalTransactionsLanded}</h4>
              </div>
            </div>{" "}
            <div
              className={styles["overviewItem"]}
              onClick={() => setActiveChart("successRate")}
            >
              <div
                className={styles["overviewItem__icon"]}
                style={{ background: "#7DFFB7" }}
              >
                {successRateIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Success Rate %</p>
                <h4>{usageData.totalSuccessRate}%</h4>
              </div>
            </div>{" "}
            <div
              className={styles["overviewItem"]}
              onClick={() => setActiveChart("medianLandingTime")}
            >
              <div
                className={styles["overviewItem__icon"]}
                style={{ background: "#60E4FC" }}
              >
                {medianIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Median Landing Time</p>
                <h4>{usageData.medianLandingTime} slot{usageData.medianLandingTime > 1 ? "s" : ""}</h4>
              </div>
            </div>
          </div>
        </div>
        <Chart
          timeSeriesMetrics={usageData.timeSeriesMetrics}
          grouping={usageData.grouping}
          activeChart={activeChart}
        />
      </div>
    </div>
  );
}
